import { IconButton } from '@material-ui/core';
import { ZoomIn, ZoomOut, ZoomOutMap } from '@material-ui/icons';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

import { MaterialInfographic } from 'models/contentfulModels';

type Props = {
  infographic?: MaterialInfographic;
}

const MaterialInfograph = (props: Props): JSX.Element => {

  const data = useStaticQuery(graphql`
    query {
      contentfulAsset(title: {eq: "Path 210"}) {
        fluid(maxWidth: 1920) {
          ...GatsbyContentfulFluid_noBase64
        }
      }
    }
  `);
  const src = props.infographic ? props.infographic.file.url : '';

  return (
    <div className="material-inf">
      <div className="material-inf__text material-inf__text--screen">
        <p>
          Skorzystaj z narzędzi do przybliżania i oddalania grafu:
        </p>
      </div>
      <div className="material-inf__image">
        <TransformWrapper
          defaultScale={1}
          defaultPositionX={200}
          defaultPositionY={100}
          wheel={{ step: 2 }}
          zoomIn={{ step: 10 }}
          zoomOut={{ step: 10 }}
        >
          {({ zoomIn, zoomOut, resetTransform }): React.ReactFragment => (
            <React.Fragment>
              <div className="material-inf__tools">
                <IconButton onClick={zoomIn}><ZoomIn fontSize="inherit" /></IconButton>
                <IconButton onClick={zoomOut}><ZoomOut fontSize="inherit" /></IconButton>
                <IconButton onClick={resetTransform}><ZoomOutMap fontSize="inherit" /></IconButton>
              </div>
              <TransformComponent>
                <img src={src} width="100%" height="auto" alt="img" />
              </TransformComponent>
            </React.Fragment>
          )}
        </TransformWrapper>
      </div>
      
      <div className="material-inf__text">
        <h1>
          Kliknij w poniższy link, aby pobrać graf argumentacyjny dotyczący wybranego tematu:
        </h1> 
        <a href={src}>
          pobierz infografikę!
        </a>
      </div>
      
      <Img className="wave wave--bottom" fluid={data.contentfulAsset.fluid} />
    </div>
  );
};

export default MaterialInfograph;