import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Button } from '@material-ui/core';
import { graphql, Link } from 'gatsby';
import React from 'react';

import useStyles from 'components/Headers/HeaderButton/buttonStyles';
import { ContentfulMaterial } from 'models/contentfulModels';

import Footer from '../Footer';
import PhoneHeader from '../Headers/PhoneHeader';
import StickyHeader from '../Headers/StickyHeader';
import SEO from '../SEO';

import MaterialHeader from './MaterialHeader';
import MaterialInfograph from './MaterialInfograph';

type Props = {
  data: { material: ContentfulMaterial };
}

const MaterialPost = (props: Props): JSX.Element => {

  const { title, category, slug, entryDescription, infograph, discussionReference } = props.data.material;
  const infographic = infograph ? infograph : undefined;
  const classes = useStyles();
  
  const entryText = entryDescription
    ? <div className="material-post__desc">{documentToReactComponents(entryDescription.json)}</div>
    : null;
  const discussionButton = entryDescription && discussionReference
    ? <Link to={`/dyskusje/${discussionReference.slug}`}>
      <Button className={`sticky__button ${classes.secondary}`}>PRZEJDŹ DO DYSKUSJI</Button>
    </Link>
    : null;

  return (
    <>
      <SEO title={title} />
      <StickyHeader />
      <PhoneHeader />
      <MaterialHeader title={title} category={category} slug={slug} /> 
      <div className="material-post" id="infographic">
        {entryText}
        {discussionButton}
        <MaterialInfograph infographic={infographic} />
      </div>
      <Footer />
    </>
  );
};

export const query = graphql`
  query ($slug: String!) {
    material: contentfulMaterials(slug: {eq: $slug}) {
      slug
      title
      category
      discussionReference {
        slug
      }
      entryDescription {
        json
      }
      infograph {
        file {
          url
          details {
            image {
              height
              width
            }
          }
        }
      }
    }
  }
`;

export default MaterialPost;