import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

type Props = {
  category: string;
  title: string;
  slug: string;
}

const MaterialHeader = (props: Props): JSX.Element => {

  const data = useStaticQuery(graphql`
    query {
      contentfulAsset(title: {eq: "Path 357"}) {
        fluid(maxWidth: 1920) {
          ...GatsbyContentfulFluid_noBase64
        }
      }
    }
  `);
  
  return (
    <div className="material-header">
      <div className="material-header__cat">
        {`HOME / MATERIAŁY / ${props.category}`}
      </div>
      <div className="material-header__title">
        {props.title}
      </div>
      <Link to={`/materiały/${props.slug}/#infographic`} className="material-header__arrow">
        <ArrowDownwardIcon fontSize="inherit"/>
      </Link>
      <Img className="wave wave--bottom" fluid={data.contentfulAsset.fluid} />
    </div>
  );
};

export default MaterialHeader;